import { useRef, useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import jwt_decode from "jwt-decode";
import axios from 'axios'
//import ServerContext from '../context/Server'
import AuthContext from "../context/AuthProvider";

// import axios from '../src/api/axios';

// const LOGIN_URL = "/api/login_check";

const Login = () => {
  const { setAuth, persist, setPersist, baseUrl } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/"

  const userRef = useRef();
  const errRef = useRef();

  const [ user, setUser ] = useState('');
  const [ pwd, setPwd ] = useState('');
  const [ errMsg, setErrMsg ] = useState('');

  //const server = ServerContext()

  // focus cursor on the first input
  useEffect( () => {
    userRef.current.focus();
    //console.log(server)
  },[])

  // delete message when username or password input changes
  useEffect( () => {
    setErrMsg('');
  },[user, pwd])

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      const response = await axios.post(baseUrl + '/api/login_check',
      //const response = await axios.post('https://api.jjmdev.xyz/api/login_check', 
          // JSON.stringify({user, pwd})
          {email: user, password: pwd}, //we have to pass the data the API needs
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true, // => Me da problemas con CORS
          }
      );
    
      console.log(response?.data)
      //getting token
      const accessToken = response?.data?.token; // => eyJ0eXAiOiJKV1QiL...

      //getting roles from token
      const decodedToken = jwt_decode(accessToken)
      const roles = decodedToken?.roles // => ['ROLE_ADMIN', 'ROLE_USER']
      console.log("EN LOGIN")
      console.log(accessToken)
      console.log(decodedToken.roles)
      console.log("EN LOGIN")
      
      setAuth({user, pwd, roles, accessToken}) // => saved in the global context

      setUser('');
      setPwd('');
      navigate(from, {replace: true});
  
    } catch (err) {
        if(!err?.response){
          setErrMsg('No server Response')
        } else if (err.response?.status === 400){
          setErrMsg('Missing Username or Password')
        } else if (err.response?.status === 401){
          setErrMsg('Unauthorized')
        } else {
          setErrMsg('Login Failed')
        }
        errRef.current.focus(); // => for screen reader
    }
  }

  const togglePersist = () => {
    setPersist(prev => !prev) 
  }

  useEffect( () => {
    localStorage.setItem("persist", persist)
  }, [persist])

  return (
      <section>
        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
        <h1>Sign In</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor='username'>Username:</label>
          <input
            type="text"
            id="username"
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setUser(e.target.value)}
            value={user}
            required
          />
          <label htmlFor='password'>Password:</label>
          <input
            type="password"
            id="password"
            onChange={(e) => setPwd(e.target.value)}
            value={pwd}
            required
          />
          <button>Sign In</button>
          <div className='persistCheck'>
            <input 
              type="checkbox" 
              id="persist"
              onChange={togglePersist}
              checked={persist}
            />
            <label htmlFor='persist'>Trust this device</label>
          </div>
        </form>
        <p>
          Need an account?<br />
          <span className='line'>
            {/* Put router here */}
            {/* <a href='#'>Sing Up</a> */}
          </span>
        </p>
        <div className="flexGrow">
          <Link to="/">Home</Link>
        </div>      
      </section>
  )
}

export default Login