import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAuth from '../hooks/useAuth';
//import axios from "axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Spinner from "./Spinner";
//import useRefreshToken from "../hooks/useRefreshToken"; // => for testing with button

const Users = () => {
  const { auth, setAuth, baseUrl } = useAuth();
  const [users, setUsers] = useState();
  const axiosPrivate = useAxiosPrivate()
  //const refresh = useRefreshToken()  // => for testing with button
  const navigate = useNavigate()
  const location = useLocation()

  const logout = async () => {
    // if used in more components, this should be in context 
    // axios to /logout endpoint 
    setAuth({});
    navigate('/login', { state: {from: location }, replace: true})
  }

  useEffect( () => {
    // let rawData = ''
    let isMounted = true
    const controller = new AbortController()
    
    const getUsers = async () => {
      console.log("Auth")
      console.log(auth) // => object
      console.log("Auth.Token")
      console.log(auth?.accessToken)

      try {
          const response = await axiosPrivate.get(baseUrl + '/user',
          //const response = await axiosPrivate.get('https://api.jjmdev.xyz/user',
            { 
              signal: controller.signal,
              //headers: {"Authorization" : `Bearer ${auth.accessToken }`}
            }
          );
          console.log("CONTROLLER SIGNAL")
          console.log(controller.signal)
          console.log("CONTROLLER SIGNAL")
          //console.log("RESPONSE DATA")
          //console.log(response.data)
          isMounted && setUsers(response.data)
          
          // rawData = response?.data.filter(i => i.email === auth.user)
          // setUsers(rawData[0].id)
        } catch (err) {
            console.log("ERROR FROM USER COMPONENT USER EFFECT AXIOS")
            if(err.code === 'ERR_BAD_REQUEST'){
              //navigate('/login', { state: {from: location }, replace: true})
              console.log("NAVIGATE TO LOGIN")
              logout()
              //setAuth({});

              //// ATENCION user SIGUE RETORNANDO el usuario logeado a pesar de tener los tokens caducados. Hay que forzar el Logout
            }
      }
    }

    getUsers()

    //clean up function when component unmount
    return () => {
      isMounted = false
      controller.abort()
    }

  },[])

 
  return (
    <>
      <h2>TEST PARA FILTRAR DATOS DESDE LA BASE DE DATOS</h2>
      <h3>Me puede servir para filtrar datos como articulos, etc...</h3>
      <article>
        <h2>User List</h2>
        { users?.length
          ? <ul>
              {users.map( (user, i) => <li key={i}>{user?.email}</li>)}
            </ul>
          : !users
            ? <Spinner />
            : <p>No users found</p>
        }
      </article>
      <br />
      <div className="flexGrow">
          <Link to="/">Home</Link>
      </div>
      <br />
      {/* <h3>ALL USERS:</h3> */}
      {/* <p>{JSON.stringify(users)}</p> */}
      {/* <p>Current User email: { JSON.stringify(auth) }</p>
      <p>Current User token: { auth.accessToken }</p> */}
      {/* <button onClick={() => refresh()}>Refresh</button>
      <br /> */}
    </>
  )
}

export default Users