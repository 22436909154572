import axios from 'axios';
const BASE_URL = 'http://localhost:8000'
//const BASE_URL = 'https://api.jjmdev.xyz'

export default axios.create({
  baseUrl: BASE_URL
});

export const axiosPrivate = axios.create({
  baseUrl: BASE_URL,
  headers: { 'Content-Type': 'application/json'},
  withCredentials: true
});