import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";
import Spinner from "./Spinner";


const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true)
  const refresh = useRefreshToken()
  const {auth, persist} = useAuth()

  useEffect(() => {
    let isMounted = true

    const verifyRefreshToken = async () => {
      try{
        await refresh() // => return response.data.token before we get to the requireAuth component that will send to the Login
      } 
      catch(err){
        console.log("Error FROM VERIFY REFRESH TOKEN")
        console.log(err)
        console.log("Error FROM VERIFY REFRESH TOKEN")
      } 
      finally {
        console.log("SET LOADING TO FALSE")
        isMounted && setIsLoading(false) // it will always execute
      }
    }
    
    // Verify only if we need to verify the RefreshToken
    console.log("Auth from persist login component")
    console.log(auth)
    console.log("Auth from persist login component")
    console.log("AUTH ACCES TOKEN")
    console.log(auth.accessToken)
    console.log("AUTH ACCES TOKEN")
    // persist added here AFTER tutorial video
    // Avoids unwanted call to verifyRefreshToken
    !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false)

    return () => isMounted = false
  }, [])
  
  useEffect(() => {
    console.log(`isLoading: ${isLoading}`)
    console.log(`Auth: ${JSON.stringify(auth?.accessToken)}`)

  }, [isLoading])
  

  return (
    <>
      {
        !persist
          ? <Outlet />
          :isLoading
            ? <>
                <p>Loading...</p>
                <Spinner />
              </>
            : <Outlet />
      }
    </>
  )

}

export default PersistLogin

