import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "./Spinner";
import useAuth from '../hooks/useAuth';
import axios from "axios";

const Articles = () => {
  const { auth, baseUrl } = useAuth();

  const [articles, setArticles] = useState();

  useEffect( () => {
    let rawData = ''

    const getArticles = async () => {

      try {
          const response = await axios.get(baseUrl + '/articles',
          //const response = await axios.get('http://localhost:8000/articles',
          //const response = await axios.get('https://api.jjmdev.xyz/articles',
            { 
              headers: {"Authorization" : `Bearer ${auth.accessToken }`}
            }
          );
          
          // rawData = response?.data.filter(i => i.author === auth.user)
          // setArticles(rawData)
          console.log(response.data)
          setArticles(response.data)
        } catch (err) {
            console.log(err)
      }
    }

    getArticles()

  },[])

  
  return (
    <>
      <h2>Article List</h2>
      <article>
        { articles?.length
          ? (
              articles.map( (article, i) => <ul key={i}>
                <li>ID: {article.id}</li>
                <li>Title: {article.title}</li>
                <li>Description: {article.description}</li>
                <li>Author: {article.author}</li>
              </ul>)
          ) : !articles
          ? <Spinner />
          : <p>No articles found</p> 
        }
      </article>
      {/* <p>{JSON.stringify(articles)}</p> */}
      <br></br>
      <div className="flexGrow">
          <Link to="/">Home</Link>
      </div>
    </>
  )
}

export default Articles