import { Outlet } from 'react-router-dom'

const Layout = () => {
  return (
    <main className='App'>
      {/* OUTLET -> represents all the children of the Layout component */}
      <Outlet />
    </main>
  )
}

export default Layout