import { Link } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import Users from "./Users";


const AdminDashboard = () => {
  const { auth } = useAuth();

  return (
    <>
      <div>AdminDashboard</div>
      <Users/>
      <br/>
      <p>Auth Roles: {JSON.stringify(auth?.roles)}</p>
      <div className="flexGrow">
          <Link to="/">Home</Link>
      </div>
    </>
  )
}

export default AdminDashboard