import { useNavigate, Link, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthProvider";
import useAuth from '../hooks/useAuth';
import jwt_decode from "jwt-decode";
// import axios from "../api/axios";
import useLogout from "../hooks/useLogout";
import axios from 'axios'


const Home = () => {
  const { setAuth } = useContext(AuthContext);
  const { auth, baseUrl } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useLogout();
  
  const [ session, setSession ] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const signOut = async () => {
    await logout()   
  }

  // IMPLEMENTAR EL CAMBIO ENTRE LOGIN / LOGOUT 
  useEffect(() => {
    console.log("AUTH")
    if(auth){
      console.log(auth)
    } else {
      console.log("No user found")
    }
    console.log("AUTH")
  }, [auth])

  useEffect( () => {
    //getting roles from token
    const decodedToken = jwt_decode(auth?.accessToken)
    const user = decodedToken?.username // => ['ROLE_ADMIN', 'ROLE_USER']
    
    setAuth(prev => {
      return {
        ...prev, 
        user: user
      }
    })
    console.log("AUTH")
    console.log(auth)
  },[])

  return (
    <section>
        <h1>Home</h1>
        { auth?.roles
          ? <p>You are Login as : { JSON.stringify(auth.user)}</p> 
          : <p>Please Login</p>
        }
        <br />
        <p>{ baseUrl }</p>
        <br />
        <Link to="/login">Go to the Login Page</Link>
        <br />
        <Link to="/blog">Go to the BLOG</Link>
        <br />
        <h2>Protected</h2>
        <h3>User only</h3>
        <Link to="/userPage">Go to the User Dashboard</Link>
        <br />
        <h3>Admin only</h3>
        <Link to="/admin">Go to the Admin Dashboard</Link>
        <br />
        <Link to="/users">Go to the Users List</Link>
        <br />
        <Link to="/articles">Go to the Articles List</Link>
        <br />
        <Link to="/products">Go to the Products List</Link>
        <br />
        <Link to="/signup">Go to the Signup Page</Link>
        <br />
        { 
          auth?.roles
            ? <button onClick={signOut}>Log Out</button>
            : (<div className="flexGrow">
                <Link to="/login" className="btn-link">Login</Link>
              </div>)
        }
    </section>
  )
}

export default Home