import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <>
    <div>Blog</div>
    <div className="flexGrow">
        <Link to="/">Home</Link>
    </div>
  </>
  )
}

export default Blog