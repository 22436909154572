import { Link, useNavigate } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  return (
    <>
    <h1>Unauthorized</h1>
    <p>You are no authorized to go there!</p>
    <div className="flexGrow">
        <button onClick={goBack}>Go Back</button>
    </div>
  </>
  )
}

export default Unauthorized