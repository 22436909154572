// ESTE COMPONENTE CREO QUE NO ES NECESARIO
// DEPENDE DE COMO ESTE CONFIGURADO EL BACK END
// EN SYMFONY CREO QUE FUNCIONA DE MODO AUTOMATICO CON EL BUNDLE 
// QUE GENERA EL REFRESH TOKEN

import axios from "../api/axios"
import useAuth from "./useAuth"
import { useNavigate, Link, useLocation } from "react-router-dom";


const useLogout = () => {
  const { setAuth, baseUrl } = useAuth()
  const navigate = useNavigate();
  const location = useLocation();

  //We will return a function
  const logout = async () => {
    setAuth({})
    try {
      const response = await axios(baseUrl + '/api/token/invalidate', {
      //const response = await axios('https://api.jjmdev.xyz/api/token/invalidate', {
        withCredentials: true
      })
    } catch (err) {
      console.log('EROR FROM useLogout: ', err)
    }

    alert('You have Sign Out. See you soon!')
    navigate('/login', { state: {from: location }, replace: true})

  }
  return logout
}

export default useLogout