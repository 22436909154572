import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Spinner from "./Spinner";
import useAuth from '../hooks/useAuth';
import axios from "axios";

const Products = () => {
  const { auth, baseUrl } = useAuth();
  const [ products, setProducts ] = useState()

  useEffect( () => {
    const getProducts = async () => {
      try {
          const response = await axios.get(baseUrl + '/api/products',
          //const response = await axios.get('https://api.jjmdev.xyz/api/products',

            { 
              headers: {"Authorization" : `Bearer ${auth.accessToken }`}
            }
          );
          setProducts(response.data['hydra:member'])
        } catch (err) {
            console.log(err)
      }
    }

    getProducts()

  },[])

  return (
    <section>
      <h2>Products List</h2>
      <article>
        { products?.length
          ? (
            products.map( (product, i) => <ul key={i}>
                <li>ID: {product.name}</li>
                <li>Title: {product.price} €</li>
              </ul>)
          ) : !products
          ? <Spinner />
          : <p>No products found</p> 
        }
      </article>
      <br></br>
      <div className="flexGrow">
          <Link to="/">Home</Link>
      </div>
    </section>
  )
}

export default Products