//Attach the interceptos to the axios instance
import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from './useRefreshToken'
import useAuth from '../hooks/useAuth';

const useAxiosPrivate = () => {
  const refresh = useRefreshToken()
  const { auth } = useAuth()

  useEffect(() => {

    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        console.log("INTERCEPTOR CONFIG")
        console.log(config)
        console.log("INTERCEPTOR CONFIG")
        if(!config.headers['Authorization']){ // If it doesn't exits means is the first time
          config.headers['Authorization'] = `Bearer ${auth?.accessToken }`
        }
        return config
      }, (error) => Promise.reject(error)
    )
    
    const responseIntercept = axiosPrivate.interceptors.response.use(
      // response => response,
      (response) => {
        console.log("INTERCEPTOR RESPONSE")
        console.log(response)
        console.log("INTERCEPTOR RESPONSE")
        return response
      },
      async(error) => {
        //if token is expired we'll be here
        const prevRequest = error?.config
        console.log("Error CONFIG")
        console.log(prevRequest)
        // 401 Unauthorized is the status code to return when the client provides no credentials or invalid credentials. 
        // 403 Forbidden is the status code to return when a client has valid credentials but not enough privileges to 
        // perform an action on a resource
        console.log(error?.response?.status)
        // if(error?.response?.status === 403 && !prevRequest?.sent){ // => !prevRequest?.sent para evitar infinite loop (solo ejecuta una vez)
        if(error?.response?.status === 401 && !prevRequest?.sent){ // => !prevRequest?.sent para evitar infinite loop (solo ejecuta una vez)
          prevRequest.sent = true
          const newAccessToken = await refresh()
          console.log("Refresh Function")
          console.log(refresh())
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
          console.log("PrevRequest")
          console.log(prevRequest)
          return axiosPrivate(prevRequest)
        }
        return Promise.reject(error)
      }
    )
  
    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept)
      axiosPrivate.interceptors.response.eject(responseIntercept)
    }
  }, [auth, refresh])
  

  return axiosPrivate
}

export default useAxiosPrivate