import { Link } from "react-router-dom";
import useAuth from '../hooks/useAuth';

const UserDashboard = () => {
  const { auth } = useAuth();

  return (
    <>
      <div>UserDashboard</div>
      <div className="flexGrow">
          <Link to="/">Home</Link>
      </div>
      <p>Auth Roles: {JSON.stringify(auth?.roles)}</p>
      <p>Auth : {JSON.stringify(auth)}</p>
    </>
  )
}

export default UserDashboard