import axios from "../api/axios";
import useAuth from "./useAuth";
import jwt_decode from "jwt-decode";

const useRefreshToken = () => {
  const { setAuth, baseUrl } = useAuth()

  const refresh = async () => {
    const response = await axios.get('http://localhost:8000/api/token/refresh', { // => Response {"token":"eyJ0eXAiOiJK...."}
    //const response = await axios.get('https://api.jjmdev.xyz/api/token/refresh', { // => Response {"token":"eyJ0eXAiOiJK...."}
      withCredentials: true
    })
    // Get roles from token
    const decodedToken = jwt_decode(response.data.token)
    const newRoles = decodedToken?.roles

    setAuth(prev => {
      console.log("PREV")
      console.log(prev)
      console.log(JSON.stringify(prev)) // => {"user":"admin@admin.com","pwd":"123456","accessToken":"eyJ0eXAiOiJKV....."}
      //console.log("PREV ROLES")
      //console.log(prev.roles)
      //console.log("RESPONSE DATA ")
      //console.log(response.data) // => {token: 'eyJ0eXAiOiJKV1QiLCJ....'}
      //console.log("RESPONSE DATA TOKEN")
      console.log(response.data.token) // => eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2NjA2NTEwMTMsImV4c....
      //console.log("ROLES FROM DECODED TOKEN 2")
      //console.log(newRoles) // => ['ROLE_ADMIN', 'ROLE_USER']
      //console.log("ROLES FROM DECODED TOKEN 2")

      return {
        ...prev, 
        roles: newRoles,
        accessToken: response.data.token
      }
    })

    return response.data.token
  }
  return refresh
}

export default useRefreshToken