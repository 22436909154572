import './App.css';
import Layout from './components/Layout';
import Login from './components/Login';
import Blog from './components/Blog';
import Home from './components/Home';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import AdminDashboard from './components/AdminDashboard';
import UserDashboard from './components/UserDashboard';
import Users from './components/Users';
import Articles from './components/Articles';
//import Signup from './components/Signup';
import {Routes, Route} from 'react-router-dom';
import PersistLogin from './components/PersistLogin';
import RequireAuth from './components/RequiredAuth';
import Products from './components/Products';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path='login' element={<Login />} />
        <Route path='blog' element={<Blog />} />
        <Route path='unauthorized' element={<Unauthorized />} />
        <Route path='products' element={<Products />} />
        {/* <Route path='signup' element={<Signup />} /> */}
 
        {/* protected routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={["ROLE_USER"]}/>}>
            <Route path='/' element={<Home />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["ROLE_ADMIN"]}/>}>
            <Route path='admin' element={<AdminDashboard />} />
            <Route path='users' element={<Users />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["ROLE_USER", "ROLE_ADMIN"]}/>}>
            <Route path='articles' element={<Articles />} />
            <Route path='userPage' element={<UserDashboard />} />
          </Route>
        </Route>
        
        {/* catch all */}
        <Route path='*' element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
